export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
    },

    // 테스트
    {
        path: '/test',
        component: () => import(/* webpackChunkName: "Common" */ './test/index.vue'),
    },

    // 정책 페이지
    {
        path: '/policy',
        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
        children: [
            {
                path: 'privacy',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/policy/privacy.vue')
            },
        ]
    },

    // 관리자 페이지
    {
        path: '/admin',
        meta: { sitemap: { ignoreRoute: true } },
        component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/admin/index.vue'),
        children: [
            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/logout.vue')
                    },
                ]
            },

            // 포켓몬 관리
            {
                path: 'pokemon',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/pokemon.vue'),
            },

            // 특성 관리
            {
                path: 'ability',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/ability.vue'),
            },

            // 기술 관리
            {
                path: 'move',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/move.vue'),
            },

            // 진화과정 관리
            {
                path: 'evolution',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/evolution.vue'),
            },

            // 도감설명 관리
            {
                path: 'docs_explain',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/docs_explain.vue'),
            },

            // 관리자 정보수정
            {
                path: 'update',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/update.vue'),
            },
        ]
    }
]