import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { routes } from './routes.js'
import store from './store.js'
import vuetify from './plugins/vuetify'
import lodash from 'lodash'
import '@babel/polyfill'

// 글로벌 CSS 정의
import './styles/global.css'

// 개발 환경에서만 적용되도록 설정
if (process.env.NODE_ENV === 'development') {
    Vue.config.productionTip = false
    Vue.config.devtools = true
    Vue.config.performance = true
    Vue.config.debug = true
    Vue.config.silent = false
    Vue.config.errorHandler = function(err, vm, info) {
        console.error('Vue Error:', err)
        console.error('Component:', vm.$options.name || vm.$options._componentTag)
        console.error('Error Info:', info)
        console.trace()
    }
}

// Axios
import axios from 'axios'
Vue.prototype.$http = axios

// 링크 복사 ($copytext)
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// CustomMethods
import CustomMethods from './methods'
Vue.use(CustomMethods)

Vue.use(Router)

Vue.config.productionTip = false

export const eventBus = new Vue()

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 페이지별 Title
router.beforeEach(async(to, from, next) => {
    let title = ''

    if(to.path.startsWith('/admin')){
        title = '관리자페이지'
    }

    document.title = title + (title==''?'':' - ') + '스마트누오'
    next()
})

new Vue({
    router,
    store,
    vuetify,
    lodash,
    render: h => h(App)
}).$mount('#app')